@import 'https://fonts.googleapis.com/css?family=Inconsolata';

._1Co6x {
  color: var(--color-text)
}

._qJWrD {
  --color-error-message: #80ff80;

  box-sizing: border-box;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: 'Inconsolata', Helvetica, sans-serif;
  font-size: 1.5rem;
  color: rgb(from var(--color-error-message) r g b / 80%);
  text-shadow:
    0 0 1ex var(--color-error-message),
    0 0 2px rgb(from var(--color-text) r g b / 80%);
}

._3p3aj {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url('./assets/noise.gif');
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.03;
}

._2AcsI {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  background: repeating-linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0,
    rgba(0, 0, 0, 0.3) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  background-size: auto 4px;
  z-index: 1;
}

._2AcsI::before {
  content: "";
  pointer-events: none;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    0deg,
    transparent 0%,
    rgb(from var(--color-background) r g b / 20%) 2%,
    rgb(from var(--color-background) r g b / 80%) 3%,
    rgb(from var(--color-background) r g b / 20%) 3%,
    transparent 100%
  );
  background-repeat: no-repeat;
  animation: _3d85T 7.5s linear 0s infinite;
}

@keyframes _3d85T {
  0% {
    background-position: 0 -100vh;
  }
  35%, 100% {
    background-position: 0 100vh;
  }
}

._33i5F {
  box-sizing: inherit;
  position: absolute;
  height: 100%;
  width: 1000px;
  max-width: 100%;
  padding: 4rem;
  text-transform: uppercase;
}

._Tgqy1 {
  color: rgb(from var(--color-error-message) r g b / 80%);
  text-shadow:
    0 0 0px rgb(from var(--color-error-message) r g b / 40%),
    0 0 2px rgb(from var(--color-text) r g b / 80%);
}

._Tgqy1::before {
  content: "> ";
}

._Tgqy1 a {
  color: var(--color-text);
  text-decoration: none;
}

._Tgqy1 a::before {
  content: "[";
}

._Tgqy1 a::after {
  content: "]";
}

._35w95 {
  text-align: center;
}

._35w95 > * {
  max-height: 100%;
  max-width: 100%;
}

._4EQzn {
  max-width: 70%;
}

._2v6hd {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: inherit;
  height: 100%;
}

._1oBKX {
  width: 20em;
  height: 20em;
  font-size: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

._1oBKX ._2HZuO {
  position: absolute;
  border-radius: 50%;
  border-style: solid;
  animation: _35raV 3s linear infinite;
}

._1oBKX ._2HZuO:nth-child(1) {
  width: 100%;
  height: 100%;
  color: gold;
  border-color: currentColor transparent transparent currentColor;
  border-width: 0.2em 0.2em 0em 0em;
  --deg: -45deg;
  animation-direction: normal;
}

._1oBKX ._2HZuO:nth-child(2) {
  width: 70%;
  height: 70%;
  color: lime;
  border-color: currentColor currentColor transparent transparent;
  border-width: 0.2em 0em 0em 0.2em;
  --deg: -135deg;
  animation-direction: reverse;
}

._1oBKX ._2HZuO ._2bxq4 {
  position: absolute;
  width: 50%;
  height: 0.1em;
  top: 50%;
  left: 50%;
  background-color: transparent;
  transform: rotate(var(--deg));
  transform-origin: left;
}

._1oBKX ._2HZuO ._2bxq4::before {
  position: absolute;
  top: -0.5em;
  right: -0.5em;
  content: '';
  width: 1em;
  height: 1em;
  background-color: currentColor;
  border-radius: 50%;
  box-shadow:
    0 0 2em,
    0 0 4em,
    0 0 6em,
    0 0 8em,
    0 0 10em,
    0 0 0 0.5em rgba(255, 255, 0, 0.1);
}

@keyframes _35raV {
  to {
    transform: rotate(1turn);
  }
}

._3KTL- {
  display: flex;
  font-size: initial;
}

._4W2yx {

}

._3i9UW {

}

._1ibg6 {

}

._1anHT {
  height: calc(100% - 2em);
  position: absolute;
  top: 2em;
  width: 100%;
}

._2lGwV {

}

._3Jpq5 {
  background-color: var(--color-background);
  display: flex;
  font-size: x-small;
  height: 3em;
  left: 0em;
  justify-content: space-between;
  position: fixed;
  top: 0em;
  transition: font-size 500ms, height 500ms;
  width: 100%;
  z-index: 1;
}

._3Jpq5:hover {
  font-size: initial;
}
